<template>
    <div class="home-page">
        <Header/>
        <div class="main-content">
            <img class="b-tc" src="../assets/tcbanner.png">
            <div class="title">大赛动态</div>
            <div class="p-content">
                <div class="p-header">当前位置：首页>>大赛动态>>{{type===2?'通知详情':'新闻详情'}}</div>
                <div class="p-title">{{obj.title}}</div>
                <div class="line"/>
                <div class="p-desc" v-html="obj.content"></div>
            </div>
            
        </div>
        
        <Footer/>
    </div>
</template>
<script>

export default {
    components:{
       
    },
    data() {
        return {
            id: '',
            type:2,
            page:1,
            obj:{},
        }
    },
    mounted() {
        Number(this.$route.query.type) === 2?this.type=2:this.type=3;
        this.id = this.$route.query.id;
        this.getData();
    },
    methods: {
        async getData(){
            const res = await this.$http.get('/api/index/getNewsDetail',{id: this.id});
            this.obj = res;
        },
    },
}
</script>
<style lang="scss" scoped>
.main-page{
    width: 1200px;
    margin: auto;
}
.main-content{
    width:1200px;
    margin: auto;
    min-height: 500px;
    position: relative;
    .title{
        height:89px;
        line-height: 89px;
        font-size: 28px;
        padding-left: 64px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .b-tc{
        width:1920px;
        z-index:-1;
        position: absolute;
        left: calc(50% - 960px);
    }
    .p-content{
        width:100%;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.17);
        padding: 0 17px 15px 17px;
        .p-header{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #343434;
            height: 51px;
            line-height: 51px;
        }
        .p-title{
            width:100%;
            height: 51px;
            line-height: 51px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #343434;
            text-align: center;
        }
        .line{
            width:100%;
            height: 1px;
            background: #BFBFBF;
        }
        .p-desc{
            padding: 17px 0;
        }
    }
}
</style>
